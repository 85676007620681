
import React from 'react'
import Helmet from 'react-helmet'
import styles from '~/styles/404.module.css'

export default function () {
    return <>
        <Helmet>
            <link href="https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet" />
        </Helmet>

        <div className={styles.container}>
            <div className={styles.subContainer}>
                <div className={styles.textContainer}>
                    <h1>Oops!</h1>
                    <h2>404 - the page cannot be found</h2>
                </div>
                <a href="/">go tutor4dev.com</a>
            </div>
        </div>
    </>
}